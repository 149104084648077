import ActionBoxes from "@/components/v2/molecules/Store/ActionBoxes";
import FreeCookieDay from "@/components/v2/molecules/Store/FreeCookieDay";
import StoreHero from "@/components/v2/molecules/Store/Hero";
import StoreInfo from "@/components/v2/molecules/Store/Info";
import StoreMenu from "@/components/v2/molecules/Store/Menu";
import NearbyLocations from "@/components/v2/molecules/Store/NearbyLocations";
import SubscriptionBox from "@/components/v2/molecules/Store/SubscriptionBox";
import BasicLayout from "@/components/v2/templates/BasicLayout/BasicLayout";
import { Region, StoreForSlugDocument } from "@/generated/requests/services";
import { regionDomainMap } from "@/static/lib/util";
import type { ApolloQueryResult } from "@apollo/client";
import dayjs from "dayjs";
import type { GetStaticPaths, InferGetStaticPropsType } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";
import {
  AllActiveStoreSlugsDocument,
  StoreProfilePageDocument,
  type StoreProfilePageQuery,
} from "../generated/requests/backend";
import { Service, serverClient } from "../lib/apollo";
// import { PHASE_PRODUCTION_BUILD } from "next/dist/shared/lib/constants";

type StorePageProps = InferGetStaticPropsType<typeof getStaticProps>;

export const getGrandOpeningDate = (store) => {
  const today = dayjs();

  if (store.storeHours?.marketingOpeningDate) {
    const grandOpening = dayjs(store.storeHours?.marketingOpeningDate);
    if (grandOpening.isAfter(today) || grandOpening.isSame(today, "day")) {
      return grandOpening;
    }
  }

  if (store.storeHours?.startDate) {
    const softOpening = dayjs(store.storeHours?.startDate);
    if (softOpening.isAfter(today)) {
      return softOpening;
    }
  }

  return null;
};

export default function StorePage({ data }: StorePageProps) {
  if (!data) {
    return null;
  }
  const { store, cookies, currentCookieWeek, slug, picks } = data;
  const domain = regionDomainMap[store.region] || regionDomainMap[Region.Us];
  const openingSoonDate = getGrandOpeningDate(store);
  const isGrandOpening = !!openingSoonDate;
  const { nearbyStores, reviews, googleReviewLink } = store;
  const storeName = store?.name;
  const showFreeCookieDay = store?.freeCookieDayDate && dayjs().isSameOrBefore(store?.freeCookieDayDate, "day");

  return (
    <>
      {/**
       * Canonical URLs required on store pages to reduce duplicate reporting
       * for marketing and SEO.
       */}
      <Head>
        <link key="canonical" rel="canonical" href={`https://crumblcookies.${domain}/${slug}`} />
      </Head>
      <StoreHero openingSoonDate={openingSoonDate} store={store} slug={slug} />
      <div className="flex flex-col items-center w-full">
        <div className="max-w-screen-2xl">
          <StoreInfo
            isGrandOpening={isGrandOpening}
            openingSoonDate={openingSoonDate}
            store={store}
            reviews={reviews}
            googleReviewLink={googleReviewLink}
          />
          {showFreeCookieDay ? <FreeCookieDay store={store} /> : <SubscriptionBox />}
          <StoreMenu
            storeName={storeName}
            currentCookieWeek={currentCookieWeek}
            nationalFlavors={cookies || []}
            hometownPicks={picks || []}
          />
          <ActionBoxes store={store} />
          {nearbyStores?.length > 0 && <NearbyLocations locations={nearbyStores} />}
        </div>
      </div>
    </>
  );
}

export const getStaticProps = async ({ params, locale = "en" }) => {
  // do not lowercase the slug. doing so will allow wrong slugs (slugs with uppercase letters) to work, which adds those urls to the sitemap. we would rather have the wrong slugs not work and redirect to 404.
  const slug = params.slug;
  // const isBuildPhase = process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD;

  try {
    const translated = await serverSideTranslations(locale, ["common", "stores", "order"]);
    let attempts = 0;
    let response: ApolloQueryResult<StoreProfilePageQuery>;
    let backoff = 500; // initial backoff time in ms

    while (attempts <= 5) {
      try {
        response = await serverClient.query({
          query: StoreProfilePageDocument,
          variables: { slug },
          context: { service: Service.backend, locale },
        });

        if (!response.data?.storeBySlug || response.data.storeBySlug.status === "TERMINATED") {
          return {
            notFound: true,
            revalidate: 60,
          };
        }
        break;
      } catch (error) {
        attempts += 1;
        if (attempts > 5) throw error;
        await new Promise((resolve) => setTimeout(resolve, backoff));
        backoff *= 2; // double the backoff time for each attempt
      }
    }

    const { currentCookieWeek, cookies, storeBySlug: store } = response.data ?? {};
    const storeForSlugResponse = await serverClient.query({
      query: StoreForSlugDocument,
      variables: { slug },
      context: { service: Service.api, locale },
    });
    const picks = storeForSlugResponse.data?.stores?.storeForSlug?.thisWeeksHometownPicks;

    return {
      revalidate: 8 * 60 * 60,
      props: {
        locale,
        data: {
          store,
          slug,
          cookies,
          currentCookieWeek,
          picks,
        },
        overrideOpenGraphTitle: store?.metaTitle,
        overrideOpenGraphDescription: store?.metaDescription,
        ...translated,
      },
    };
  } catch (error) {
    return {
      notFound: true,
      revalidate: 60,
    };
  }
};

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  const allStoreLists = [];

  // Using a for-of loop because apollo will dedupe queries if they are the same in a `Promise.all`
  for (const locale of locales) {
    const result = await serverClient.query({
      query: AllActiveStoreSlugsDocument,
      context: { service: Service.backend, locale },
    });

    if (!result.data?.allActiveStores?.length) {
      continue;
    }

    const params = result.data.allActiveStores.filter((s) => s.slug).map(({ slug }) => ({ params: { slug }, locale }));
    allStoreLists.push(...params);
  }

  return {
    paths: allStoreLists,
    fallback: "blocking",
  };
};

StorePage.getLayout = (page, props) => (
  <BasicLayout orderFromLocationPage {...props} storeName={props?.data?.store?.name} hideFullWidth mobileCta={true}>
    {page}
  </BasicLayout>
);
