import { ImagesV2 } from "@/public/images/all";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import IconClockRegular from "../../atoms/Icons/ClockRegular";
import IconMobileRegular from "../../atoms/Icons/MobileRegular";
import IconMoonRegular from "../../atoms/Icons/MoonRegular";
import IconSignDirectionRight from "../../atoms/Icons/SignDirectionRight";
import IconStoreRegular from "../../atoms/Icons/StoreRegular";
import Text from "../../atoms/Text/Text";
import ButtonLink from "../ButtonLink/ButtonLink";
import Flag from "../Flag/Flag";
dayjs.extend(isBetween);

interface Props {
  openingSoonDate: null | dayjs.Dayjs;
  store;
  slug: string;
}

export default function StoreHero({ openingSoonDate, store, slug }: Props) {
  const { t } = useTranslation("stores");
  const isGrandOpening = !!openingSoonDate;
  const flag = getFlagDetails(openingSoonDate, store);

  return (
    <div className="py-5 px-4 md:px-7 md:py-12 bg-primary">
      <div className="max-w-8xl md:flex items-center justify-between mx-auto">
        <div className="flex flex-col items-start">
          {flag && (
            <Flag
              variant="secondary-light"
              showLeftFlag={false}
              height={32}
              containerClassName="py-[6px] px-[8px] rounded-l"
            >
              {flag.icon}
              <Text className="px-1 uppercase md:text-base not-italic" variant="smallHeader">
                {flag.text}
              </Text>
              <Text variant="finePrint" className="md:text-base not-italic">
                {flag.date}
              </Text>
            </Flag>
          )}

          <Text variant="display2" className="block md:hidden py-2.5">
            {store?.name}
          </Text>
          <Text variant="display1" className="hidden md:block py-2.5 py-5">
            {store?.name}
          </Text>

          {isGrandOpening ? (
            <div>
              <Text variant="title3">{t("be_first_know")}</Text>
              <Text variant="body2" className="mt-1">
                {t("subscription_text")}
              </Text>
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-4 w-full lg:mt-6 lg:w-auto md:flex items-center">
              <ButtonLink
                href="#"
                onClick={() => window.open(encodeURI(`https://www.google.com/maps/place/${store?.address}`))}
                variant="secondary"
                className="w-full flex items-center !bg-primary"
                contentLeft={<IconSignDirectionRight />}
              >
                <span className="hidden md:inline md:visible">{t("get")}&nbsp; </span>
                {t("directions")}
              </ButtonLink>
              <ButtonLink
                href={`tel:${store?.phone}`}
                variant="secondary"
                className="w-full flex items-center !bg-primary"
                contentLeft={<IconMobileRegular />}
              >
                {t("call")}
                <span className="hidden md:inline md:visible">&nbsp;{t("store")}</span>
              </ButtonLink>
            </div>
          )}
        </div>

        <div className="hidden md:visible md:block">
          {isGrandOpening ? (
            <Image src={ImagesV2.illustrationGrandOpening} alt={t("store")} height={272} width={348} />
          ) : (
            <div className="w-[400px] xl≈:w-[600px] h-[400px] overflow-hidden relative flex items-center justify-center xl:ml-[50px]">
              <Image
                src={ImagesV2.illustrationStore4x}
                alt={t("store")}
                quality={100}
                width="0"
                height="0"
                sizes="100vw"
                className="min-w-[500px] lg:min-w-[800px] h-auto"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export const getFlagDetails = (openingSoonDate: dayjs.Dayjs, store) => {
  const { t } = useTranslation("stores");

  if (openingSoonDate) {
    return {
      icon: <IconStoreRegular width={20} height={20} className="w-[20px] h-[20px]" />,
      text: t("grand_opening"),
      date: openingSoonDate.format("MMM. D"),
    };
  }

  const today = dayjs();
  const dayOfWeek = today.format("ddd").toUpperCase();

  const openPeriodIndex = store?.storeHours?.periods?.findIndex((period) => period.dayOfWeek === dayOfWeek);
  const openPeriod = store?.storeHours?.periods?.[openPeriodIndex];
  if (openPeriod) {
    const openTime = dayjs(openPeriod.localStartTime, "H:mm");
    const closeTime = dayjs(openPeriod.localEndTime, "H:mm");

    //before start time
    //OPENS at 8 am
    if (today.isBefore(openTime)) {
      return {
        icon: <IconClockRegular className="w-5 h-5" />,
        text: t("opens"),
        date: `${t("at")} ${openTime.format("h a")}`,
      };
    }

    if (today.isBefore(closeTime)) {
      const closingTime = closeTime.format("h a") === "12 am" ? "midnight" : closeTime.format("h a");

      //what's considered "late" ?
      if (closingTime === "midnight") {
        return {
          icon: <IconMoonRegular className="w-5 h-5" />,
          text: t("open_late"),
          date: `${t("until")} ${closingTime}`,
        };
      }

      return {
        icon: <IconClockRegular className="w-5 h-5" />,
        text: t("open"),
        date: `${t("until")} ${closingTime}`,
      };
    }
  }

  //next index
  // if it's length - 1, next index is 0
  const storeHoursLength = store?.storeHours?.periods?.length;
  const nextOpenPeriod =
    store?.storeHours?.periods?.[storeHoursLength - 1 === openPeriodIndex ? 0 : openPeriodIndex + 1];

  if (!nextOpenPeriod) {
    return {
      icon: <IconClockRegular className="w-5 h-5" />,
      text: t("closed"),
      date: null,
    };
  }

  return {
    icon: <IconClockRegular className="w-5 h-5" />,
    text: t("closed"),
    date: `${t("until")} ${dayjs(nextOpenPeriod.localStartTime, "H:mm").format("h a")} ${
      {
        MON: "Monday",
        TUE: "Tuesday",
        WED: "Wednesday",
        THU: "Thursday",
        FRI: "Friday",
        SAT: "Saturday",
        SUN: "Sunday",
      }[nextOpenPeriod.dayOfWeek]
    }`,
  };
};
