import classNames from "classnames";
import Link, { type LinkProps } from "next/link";
import type { ReactNode } from "react";
import Text from "../../atoms/Text/Text";

const variants = {
  primary: "bg-black text-white",
  secondary: "bg-white text-black border-[1px] border-black",
  secondaryLight: "bg-primary-light text-black border-[1px] border-black",
} as const;

export type ButtonLinkVariant = keyof typeof variants;
export const allVariants = Object.keys(variants) as ButtonLinkVariant[];

interface ButtonProps extends LinkProps {
  className?: string;
  children?: ReactNode;
  variant?: keyof typeof variants;
  contentLeft?: ReactNode;
  contentRight?: ReactNode;
  block?: boolean;
  style?: React.CSSProperties;
  height?: number;
  openInNewTab?: boolean;
  disabled?: boolean;
}

export default function ButtonLink({
  href,
  children,
  className,
  onClick,
  variant = "primary",
  contentLeft,
  contentRight,
  block,
  style,
  height,
  openInNewTab,
  disabled,
}: ButtonProps) {
  return (
    <Link
      style={style}
      href={href || ""}
      onClick={disabled ? undefined : onClick}
      target={openInNewTab ? "_blank" : ""}
      className={classNames(
        `flex-nowrap inline-flex items-center min-h-[40px] rounded-full py-3 z-10 max-w-[400px]`,
        height ? `h-[${height}px]` : "min-h-[40px] h-[40px] sm:min-h-[50px] sm:h-[50px]",
        variants[variant],
        contentLeft ? "pl-[25px]" : "pl-[30px]",
        contentRight ? "pr-[25px]" : "pr-[30px]",
        contentLeft && contentRight ? "justify-between" : "justify-center",
        block ? "w-full" : " w-fit",
        disabled ? "opacity-50 cursor-not-allowed" : "",
        className,
      )}
    >
      {contentLeft && <span className="mr-2">{contentLeft}</span>}
      <Text as="div" variant="lineItem" className={"text-base text-nowrap font-semibold h-full flex items-center"}>
        {children}
      </Text>
      {contentRight && <span className="ml-2">{contentRight}</span>}
    </Link>
  );
}
