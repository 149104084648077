import { SourceType } from "@/generated/requests/backend";
import { getRegionCode } from "@/lib/locale-helpers";
import { ImagesV2 } from "@/public/images/all";
import { parsePhoneNumber } from "awesome-phonenumber";
import classNames from "classnames";
import dayjs from "dayjs";
import keyBy from "lodash/keyBy";
import pick from "lodash/pick";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { createElement, useEffect, useState } from "react";
import IconCarRegular from "../../atoms/Icons/CarRegular";
import IconCurbsideRegular from "../../atoms/Icons/CurbsideRegular";
import IconEnvelopeRegular from "../../atoms/Icons/EnvelopeRegular";
import IconFoodTrayRegular from "../../atoms/Icons/FoodTrayRegular";
import IconMobileRegular from "../../atoms/Icons/MobileRegular";
import IconMoonRegular from "../../atoms/Icons/MoonRegular";
import IconStoreRegular from "../../atoms/Icons/StoreRegular";
import IconTruckRegular from "../../atoms/Icons/TruckRegular";
import Text from "../../atoms/Text/Text";
import Flag from "../Flag/Flag";
import StarRating from "../StarRating/StarRating";
import { getFlagDetails } from "./Hero";

export default function StoreInfo({
  isGrandOpening,
  openingSoonDate,
  store,
  reviews,
  marketing = null,
  googleReviewLink,
}) {
  const { t } = useTranslation("stores");
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const { date: openingDate } = getFlagDetails(openingSoonDate, store) ?? {};

  // marketing team already had a lot of custom descriptions with weird background highlights and text colors. so we're overwriting them here. but besides these three things, (font-family, background-color, color) any styles they add in the internal description input will be preserved.
  const description = (store?.metadata?.marketingDescription ?? "")
    ?.replace(/font-family[^;]+;/g, "")
    ?.replace(/background-color[^;]+;/g, "")
    ?.replace(/color[^;]+;/g, "");

  const rating = reviews?.averageStarRating || 0;

  const dayOfWeekToday = dayjs().format("ddd").toUpperCase();
  const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const formattedDaysOfWeek = {
    SUN: "Sunday",
    MON: "Monday",
    TUE: "Tuesday",
    WED: "Wednesday",
    THU: "Thursday",
    FRI: "Friday",
    SAT: "Saturday",
  };
  const storeHoursByDay =
    store.storeHours?.periods?.reduce((acc, period) => {
      // Filter out closed days
      if (period.localStartTime === "Closed" || period.localEndTime === "Closed") return acc;

      acc[period.dayOfWeek] = `${dayjs(period.localStartTime, "H:mm").format("h:mmA")} - ${
        period.localEndTime === "24:00" ? "Midnight" : dayjs(period.localEndTime, "H:mm").format("h:mmA")
      }`;
      return acc;
    }, {}) || {};

  const servicesByType = keyBy(store.sources, "type");
  const servicesAtLocation = Object.entries(SERVICES)
    .filter(([service, _]) => {
      if (service === SourceType.CarryOut && store.carryoutDisabled) {
        return false;
      }
      if (service === SourceType.Pickup && !store.curbsideEnabled) {
        return false;
      }
      return servicesByType[service];
    })
    .map(([_, serviceDetails]) => serviceDetails);

  const { locale } = useRouter();
  const phone = formatPhoneNumber(store.phone, getRegionCode(locale));

  return (
    <div className="max-w-8xl mx-auto xl:flex">
      <div className="pt-2.5 pb-5 px-4 md:px-7 md:pt-12 lg:pt-24 bg-primary md:bg-transparent">
        <div
          className={classNames(
            "h-[500px] md:h-[568px] bg-black/50 rounded-[10px] md:rounded-[15px] w-full md:w-[600px] mx-auto overflow-hidden",
            isGrandOpening ? "hidden md:block md:visible" : "",
          )}
        >
          <Image
            src={store?.storefrontImageUrl || ImagesV2.sampleStoreFront}
            alt="Storefront"
            width={600}
            height={600}
            objectFit="cover"
            objectPosition="center"
            className="w-[100%] h-[100%] object-cover object-center"
          />
        </div>

        {!isGrandOpening && reviews && (
          <div className="flex flex-col sm:flex-row items-center justify-center w-full gap-2 mt-2.5">
            <StarRating rating={rating} className="mb-2" />

            <div className="flex items-center justify-center">
              <Text variant="smallHeader" className="md:text-base">
                {t("num_store_rating", { num: rating.toFixed(1) })}
              </Text>
              <Text variant="body2" className="px-1 md:text-base">
                |
              </Text>
              <Link href={googleReviewLink || ""} className="underline md:text-base" target="_blank">
                <Text variant="body2">{t("num_reviews", { num: reviews?.totalCount })}</Text>
              </Link>
            </div>
          </div>
        )}
      </div>

      <div className="py-7 px-4 md:px-7 lg:pt-24">
        {marketing}

        <Text variant="display3">
          {isGrandOpening ? t("grand_opening_date_x", { x: openingDate }) : t("about_us")}{" "}
        </Text>

        {description && isMounted ? (
          <Text
            variant="body2"
            className="mt-2.5 md:text-base"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        ) : (
          t("description_text", pick(store, ["city", "state"]))
            .split("\n")
            .map((text, i) => (
              <Text key={i} variant="body2" className="mt-2.5 md:text-base">
                {text}
              </Text>
            ))
        )}

        <div className="md:flex gap-10">
          <div className="space-y-5 my-7 xl:max-w-[40%]">
            <Text variant="body2" className="flex items-start">
              <IconStoreRegular width={20} height={20} className="mr-2 min-w-[20px]" />
              {store?.address}
            </Text>

            <Link href={`tel: ${phone}`} className="block">
              <Text variant="body2" className="flex items-center">
                <IconMobileRegular width={20} height={20} className="mr-2 min-w-[20px]" />
                {phone}
              </Text>
            </Link>

            <Link href={`mailto: ${store.email}`} className="block">
              <Text variant="body2" className="flex items-center">
                <IconEnvelopeRegular width={20} height={20} className="mr-2 min-w-[20px]" />
                {store.email}
              </Text>
            </Link>
          </div>

          <div className="my-7">
            <Text variant="lineItem">{t("hours")}</Text>
            <div className="space-y-1.5 mt-1">
              {daysOfWeek.map((d) => {
                const schedule = storeHoursByDay?.[d];
                if (!schedule) {
                  return null;
                }

                const textVariant = d === dayOfWeekToday ? "smallHeader" : "body2";

                return (
                  <div key={d} className="grid grid-cols-2 items-center justify-between md:justify-start md:gap-2">
                    <Text variant={textVariant}>{t(formattedDaysOfWeek[d].toLowerCase())}</Text>

                    <Text variant={textVariant} className="hidden md:block md:visible">
                      {schedule}
                    </Text>

                    {schedule.includes("Midnight") && (
                      <Flag variant="primary-light" showLeftFlag={false} className="-my-1 rounded-l-sm overflow-hidden">
                        <IconMoonRegular className="w-4 h-4" />
                        <Text className="px-1 italic" variant="finePrint">
                          {t("open_late")}
                        </Text>
                      </Flag>
                    )}

                    <Text variant={textVariant} className="md:hidden">
                      {schedule}
                    </Text>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {servicesAtLocation.length > 0 && (
          <div className="my-7 flex flex-col items-start">
            <Text variant="body1" className="rounded py-1.5 px-4 bg-primary mb-5">
              {t("services_at_location")}
            </Text>
            <div className="flex flex-wrap">
              {servicesAtLocation.map(({ icon, text }) => (
                <Text
                  variant="smallHeader"
                  key={text}
                  className="rounded-[10px] border border-solid border-black/20 px-5 md:px-7 py-2.5 mr-2.5 mb-2.5 md:mr-4 md:mb-4 flex items-center md:text-base"
                >
                  {createElement(icon, { className: "flex-shrink-0 mr-2.5 w-5 h-5" })}
                  {t(text)}
                </Text>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const formatPhoneNumber = (phoneNumber, regionCode = "US") => {
  const p = parsePhoneNumber(phoneNumber, { regionCode });
  if (p?.valid) {
    return p?.number?.national;
  }

  const p2 = parsePhoneNumber(phoneNumber?.replace(/\D/g, ""), { regionCode });
  if (p2?.valid) {
    return p2?.number?.national;
  }

  return phoneNumber;
};

const SERVICES = {
  DELIVERY: {
    icon: IconTruckRegular,
    text: "Delivery",
  },
  CARRY_OUT: {
    icon: IconStoreRegular,
    text: "carryout",
  },
  PICKUP: {
    icon: IconCurbsideRegular,
    text: "curbside",
  },
  CATERING: {
    icon: IconFoodTrayRegular,
    text: "catering",
  },
  DRIVETHRU: {
    icon: IconCarRegular,
    text: "drive_thru",
  },
};
